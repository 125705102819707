import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {ResultItemTemplate} from '../../../commons/components/search-result/ResultItemTemplate'
import {ActivationState, getArfbrDebugFromSessionStorage} from '../../../commons/components/settings/settings'
import {Pool} from '../../../commons/idqsa/idqsa'
import {isMobile} from '../../../commons/utils/isMobile'
import {arPathBook} from '../../store/ar.paths'
import {ResultItemProps} from '../../store/ar.types'
import {getResourceAvailabilityForUser} from '../../utils/getResourceAvailabilityForUser'
import {getResultDocumentText} from '../../utils/resultItem.utils'
import {ArBooksChapterParas} from './ArBooksChapterParas'
import {ArResultItemBreadcrumb, ChapterBreadcrumbs, createBreadcrumbItem} from './ChapterBreadcrumbs'
import {getAvailability} from './utils/getAvailability'
import {getBookTitleFromResultItem} from './utils/getBookTitleFromResultItem'

const MAX_CHAPTER_BREADCRUMBS_DESKTOP = 3
const MAX_CHAPTER_BREADCRUMBS_MOBILE = 0
export const ArBooksChapterResultItem: React.FC<ResultItemProps> = ({
  resultDocument,
  searchId,
  userInput,
  filters,
  page,
}) => {
  const {theme} = useFela<Theme>()
  const arfbrDebug = getArfbrDebugFromSessionStorage()

  const link = arPathBook(
    userInput,
    resultDocument.properties.bookId!,
    resultDocument.properties.chapterId,
    resultDocument.id,
    filters,
    page
  )

  const name = resultDocument.properties.simpleName || resultDocument.name

  const userResourceAvailability = getResourceAvailabilityForUser(getAvailability(resultDocument))

  const source = getBookTitleFromResultItem(resultDocument)
  const chapterNames = resultDocument.properties.chapterSimpleNamePath
  const chapterIdPath = resultDocument.properties.chapterIdPath
  const notAvailable = userResourceAvailability && !userResourceAvailability.available

  const items: ArResultItemBreadcrumb[] = []
  if (source) {
    items.push(createBreadcrumbItem(source, undefined, notAvailable))
  }
  if (chapterNames) {
    const length = Math.min(
      isMobile(theme) ? MAX_CHAPTER_BREADCRUMBS_MOBILE : MAX_CHAPTER_BREADCRUMBS_DESKTOP,
      chapterNames?.length
    )
    for (let i = 0; i < length; i++) {
      const chapterName = chapterNames[i]
      const chapterId = chapterIdPath ? chapterIdPath[i] : ''
      // only show items with text and do not repeat the name because a name can be a chapter name
      if (chapterName && chapterName.length > 0 && chapterName !== name) {
        items.push(createBreadcrumbItem(chapterName, chapterId, notAvailable))
      }
    }
  }

  const breadcrumbLink = (chapterId?: string) => {
    return arPathBook(userInput, resultDocument.properties.bookId!, chapterId, chapterId, filters, page)
  }
  const breadcrumbs = <ChapterBreadcrumbs items={items} breadcrumbLink={breadcrumbLink || (() => '')} />

  return (
    <ResultItemTemplate
      resultItemId={resultDocument.id}
      name={name}
      source={getBookTitleFromResultItem(resultDocument)}
      userResourceAvailability={userResourceAvailability}
      link={link}
      pool={Pool.ARFBR}
      searchId={searchId}
      type={resultDocument.properties.skriptum ? 'SKRIPTUM' : 'BUCH'}
      typeBgColor="contentTypeBookChapter"
      disableBadge={arfbrDebug === ActivationState.NO}
      breadcrumbs={breadcrumbs}
    >
      <p dangerouslySetInnerHTML={{__html: getResultDocumentText(resultDocument, 1)}} />
      <ArBooksChapterParas
        paras={resultDocument.properties.paras}
        userInput={userInput}
        bookId={resultDocument.properties.bookId!}
        chapterId={resultDocument.properties.chapterId}
        filters={filters}
        page={page}
      />
    </ResultItemTemplate>
  )
}
