import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Link} from 'react-router-dom'
import {textColorLinksRules, undecoratedLinksRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {ArFilters} from '../../store/ar.actions'
import {arPathBook} from '../../store/ar.paths'

interface Props {
  paras?: string
  userInput: string
  bookId: string
  chapterId?: string
  filters?: ArFilters
  page?: number
}

interface Para {
  id: string
  name: string
}

const getParas = (paras: string) => {
  return JSON.parse(paras) as Para[]
}

export function ArBooksChapterParas({paras: parasString, userInput, bookId, chapterId, filters, page}: Props) {
  const {theme, css} = useFela<Theme>()

  if (!parasString) {
    return null
  }

  const paras = getParas(parasString)
  return (
    <Box pl={2} pt={2}>
      {paras.map((para) => {
        const link = arPathBook(userInput, bookId, chapterId, para.id, filters, page)
        return (
          <h3 className={css({marginTop: 2, marginBottom: 1}, textColorLinksRules(theme), undecoratedLinksRules())}>
            <Link to={link}>{para.name}</Link>
          </h3>
        )
      })}
    </Box>
  )
}
