import * as React from 'react'
import {useFela} from 'react-fela'
import {authenticator, Permission} from '../../../app/auth/SessionPlusAuthenticator'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {ReactComponent as CloseIcon} from '../../icons/close.svg'
import {ReactComponent as SettingsIcon} from '../../icons/settings.svg'
import {FloatingButton} from '../floating-button/FloatingButton'
import {SettingDetailsPanel} from './SettingDetailsPanel'
import {getCurrentSetFromSessionStorage} from './settings'

interface Props {
  bottomPosition: boolean
  disableIcon?: boolean
  onToggle?: () => void
  renderSourceInfo?: () => JSX.Element
}

export const SettingsPanel: React.FC<Props> = ({bottomPosition, disableIcon, onToggle, renderSourceInfo}) => {
  const [showSettings, setShowSettings] = React.useState(false)
  const set = getCurrentSetFromSessionStorage()
  const {theme} = useFela<Theme>()
  if (!authenticator.hasPermission(Permission.SETTINGS)) {
    return null
  }
  return (
    <>
      <SettingDetailsPanel show={showSettings} renderSourceInfo={renderSourceInfo} />
      <FloatingButton
        onClick={() => {
          setShowSettings(!showSettings)
          if (onToggle) {
            onToggle()
          }
        }}
        // bottom={!bottomPosition ? 120 : 50} - moved to bottom because of CP-901
        disabled={disableIcon}
        bgColor={set === 'PREVIEW' ? theme.colors.accent : theme.colors.primaryLight}
      >
        <ThemedIcon size={20} color={theme.colors.iconInverted} hoverColor={theme.colors.iconInverted} verticalCenter>
          {showSettings ? <CloseIcon /> : <SettingsIcon />}
        </ThemedIcon>
      </FloatingButton>
    </>
  )
}
