import {Box, Col, ColRow, Grid, VStack} from '@indoqa/style-system'
import {History} from 'history'
import * as React from 'react'
import {useHistory} from 'react-router-dom'
import {ajax} from 'rxjs/ajax'
import {authenticator, Permission} from '../../app/auth/SessionPlusAuthenticator'
import {ReaderFooterPanel} from '../../commons/components/footer/ReaderFooterPanel'
import {MainContainerPanel} from '../../commons/components/main/MainContainerPanel'
import {SettingsPanel} from '../../commons/components/settings/SettingsPanel'
import {ReaderLayout} from '../../commons/layouts/reader-layout/ReaderLayout'
import {ResultDocument} from '../../commons/types/SearchResult'
import {formatDate} from '../../commons/utils/formatDate'
import {ArCallToActionPanel} from '../components/cta/ArCallToActionPanel'
import {ARHeaderPanel} from '../components/header/ARHeaderPanel'
import {ExclusivePlusLink} from '../components/links/ExclusivePlusLink'
import {ArVideoResultItem} from '../components/result-items/ArVideoResultItem'
import {getRechtsstand} from '../components/result-items/utils/getRechtsstand'
import {arPathBook} from '../store/ar.paths'
import {loadAllResources} from '../store/ar.services'
import {ArInventory} from '../store/ar.types'
import {getResourceAvailabilityForUser} from '../utils/getResourceAvailabilityForUser'

const renderBookLink = (doc: ResultDocument, titel: string, history: History) => {
  const userResourceAvailability = getResourceAvailabilityForUser(doc.availability)
  if (!userResourceAvailability.available) {
    return (
      <li key={doc.id}>
        {titel}
        <small>&nbsp;(Rechtsstand: {formatDate(getRechtsstand(doc))})</small>
      </li>
    )
  }

  return (
    <li key={doc.id}>
      <a
        href={arPathBook('', doc.id)}
        onClick={(e) => {
          e.preventDefault()
          history.push(arPathBook('', doc.id))
        }}
      >
        {titel}
      </a>
      <small>&nbsp;(Rechtsstand: {formatDate(getRechtsstand(doc))})</small>
    </li>
  )
}

export const ArContentResourcesPage = () => {
  const isPlusUser = authenticator.hasPermission(Permission.ARFBR_PLUS)
  const isPremiumUser = authenticator.hasPermission(Permission.ARFBR_PREMIUM)
  const isArfbrUser = isPlusUser || isPremiumUser

  const history = useHistory()
  const [showSettings, setShowSettings] = React.useState(false)
  const [arInventory, setArInventory] = React.useState<ArInventory | null>(null)

  const arHeader = <ARHeaderPanel />
  const arFooter = <ReaderFooterPanel />

  React.useEffect(() => {
    loadAllResources(ajax).subscribe((result) => setArInventory(result))
  }, [])

  return (
    <ReaderLayout header={arHeader} footer={arFooter} enableScrollTop={true}>
      <MainContainerPanel>
        {arInventory && (
          <Box fullWidth>
            {!isArfbrUser && <ArCallToActionPanel />}
            <VStack spacing={4}>
              <Box pt={isArfbrUser ? 0 : 8}>
                <h2>
                  Bücher <ExclusivePlusLink />
                </h2>
                <ul>
                  {arInventory.books.documents
                    ?.filter((doc) => !doc.properties.skriptum)
                    .map((doc) => renderBookLink(doc, doc.properties.quelle_lang || doc.name, history))}
                </ul>
              </Box>
              <Box>
                <h2>VÖGB-Skripten</h2>
                <ul>
                  {arInventory.books.documents
                    ?.filter((doc) => doc.properties.skriptum)
                    .map((doc) => renderBookLink(doc, doc.properties.quelle_lang || doc.name, history))}
                </ul>
              </Box>
              <Box>
                <h2>Videos</h2>
                <Grid spacing="3rem">
                  <ColRow>
                    {arInventory.videos.documents
                      ?.sort((doc1, doc2) => doc1.name.localeCompare(doc2.name))
                      .map((videoDocument) => {
                        return (
                          <Col size={[12, 4]} key={videoDocument.id}>
                            <Box mb={[1, 0]}>
                              <ArVideoResultItem
                                resultDocument={videoDocument}
                                userInput=""
                                showNameBelow
                                height={185}
                              />
                            </Box>
                          </Col>
                        )
                      })}
                  </ColRow>
                </Grid>
              </Box>
            </VStack>
          </Box>
        )}
      </MainContainerPanel>
      <SettingsPanel bottomPosition={false} onToggle={() => setShowSettings(!showSettings)} />
    </ReaderLayout>
  )
}
