import {getCurrentSetFromSessionStorage} from '../components/settings/settings'

export const getHeaders = () => {
  const headers = {
    Accept: 'application/json',
  }
  const currentSet = getCurrentSetFromSessionStorage()
  if (currentSet === 'PREVIEW') {
    headers['set'] = currentSet
  }

  // the SESSION cookie should handle this
  // const token = authenticator.getToken()
  // const tokenType = authenticator.getTokenType()
  // if (token && tokenType) {
  //   Object.assign(headers, {Authorization: `${tokenType} ${token}`})
  // }
  return headers
}
