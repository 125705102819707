import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {useSelector} from 'react-redux'
import {authenticator, Permission} from '../../../app/auth/SessionPlusAuthenticator'
import {noPrintRule} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {ValidityDatePicker} from '../../../commons/components/data-picker/ValidityDatePicker'
import {Breadcrumb} from '../../../commons/components/header/ReaderBreadcrumbsPanel'
import {ReaderHeaderPanel} from '../../../commons/components/header/ReaderHeaderPanel'
import {ReaderSearchInput} from '../../../commons/components/header/ReaderSearchInput'
import {ReaderSearchInputPanel} from '../../../commons/components/header/ReaderSearchInputPanel'
import {ReaderToolbarPanel} from '../../../commons/components/header/ReaderToolbarPanel'
import {PrinterIcon} from '../../../commons/components/printer-icon/PrinterIcon'
import {ReaderLayoutContext, ReaderLayoutContextValue} from '../../../commons/layouts/reader-layout/ReaderLayoutContext'
import {DocumentInfo} from '../../../commons/types/DocumentInfo'
import {isMobile} from '../../../commons/utils/isMobile'
import {DigitalLogo} from '../../../digital/components/digital-logo/DigitalLogo'
import {HIST_GEHALTSTABELLE_NAME} from '../../store/kv.constants'
import {kvPathDocument, kvPathKvDocSetId, kvPathOverview} from '../../store/kv.paths'
import {selectKvSearchFilters, selectKvSearchUserQuery} from '../../store/kv.selectors'
import {FeedbackIcon} from '../feedback/FeedbackIcon'
import {FeedbackPanel} from '../feedback/FeedbackPanel'
import {QuickSearchSelect} from '../quick-search/QuickSearchSelect'
import {KvSourceInfoPanel} from '../source-info/KvSourceInfoPanel'
import {KvReaderMobileModalSearch} from './KvReaderMobileModalSearch'

export type ChangeValidityDate = (nextDate: Date) => void
export type ExecuteQuickSearch = (nextQuickSearch: string) => void
export type ExecuteSearch = (userQuery: string) => void
export type ResetAllSearch = () => void

interface Props {
  name: string
  docSetId: string
  loading: boolean
  documentInfo: DocumentInfo
  paraId?: string
  validityDate?: Date
  changeValidityDate: ChangeValidityDate
  quickSearch?: string
  executeQuickSearch: ExecuteQuickSearch
  userQuery?: string
  executeSearch: ExecuteSearch
  resetAllSearch: ResetAllSearch
}

export const KvReaderHeaderPanel: React.FC<Props> = (props) => {
  const {
    name,
    docSetId,
    loading,
    documentInfo,
    paraId,
    validityDate,
    changeValidityDate,
    quickSearch,
    executeQuickSearch,
    userQuery,
    executeSearch,
    resetAllSearch,
  } = props
  const {theme} = useFela<Theme>()
  const {setHeaderHeight, enableNav} = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  const [showFeedbackPanel, setShowFeedbackPanel] = React.useState<boolean>(false)
  const mobile = isMobile(theme)
  const permissionKVS = authenticator.hasPermission(Permission.KVSYSTEM)

  React.useLayoutEffect(() => {
    const headerHeight = theme.layout.reader.headerHeight + theme.layout.reader.toolbarHeight
    setHeaderHeight(headerHeight)
  }, [setHeaderHeight, theme.layout.reader.headerHeight, theme.layout.reader.toolbarHeight])

  const searchUserQuery = useSelector(selectKvSearchUserQuery)
  const searchFilters = useSelector(selectKvSearchFilters)

  const hasFilter =
    (searchFilters &&
      (searchFilters.ang_arb ||
        searchFilters.province ||
        searchFilters.sector ||
        searchFilters.union ||
        searchFilters.validOn)) ||
    searchUserQuery
  const breadcrumbs: Breadcrumb[] = [
    {
      name: hasFilter ? 'Zurück zum Suchergebnis' : 'Kollektivverträge',
      linkTo: kvPathOverview(searchUserQuery, searchFilters || undefined),
    },
    {name, linkTo: kvPathKvDocSetId(docSetId)},
  ]
  if (documentInfo && documentInfo.documentName && documentInfo.documentId) {
    breadcrumbs.push({
      name: documentInfo.documentName,
      linkTo: kvPathDocument(docSetId, documentInfo.documentId, name, documentInfo.documentName),
    })
  }

  const logo = <DigitalLogo small />
  const searchInput = (
    <ReaderSearchInput
      onSearch={(userInput) => {
        if (mobile) {
          enableNav()
        }
        executeSearch(userInput)
      }}
      userQuery={userQuery}
      placeholder="Diesen Kollektivvertrag durchsuchen"
      disabled={loading || !permissionKVS}
    />
  )
  const quickSearchInput = (
    <QuickSearchSelect
      onChange={(qs) => {
        if (mobile) {
          enableNav()
        }
        executeQuickSearch(qs)
      }}
      selected={quickSearch}
      disabled={loading || !permissionKVS}
    />
  )
  const mobileSearch = (
    <KvReaderMobileModalSearch
      searchInput={searchInput}
      quickSearchInput={quickSearchInput}
      resetAllSearch={resetAllSearch}
      documentInfo={documentInfo}
      docsetName={name}
    />
  )
  const searchPanel = <ReaderSearchInputPanel tablet={searchInput} mobile={mobileSearch} />
  const tools =
    documentInfo.documentId !== undefined || loading ? (
      <Flex alignItems="center" style={noPrintRule()}>
        <PrinterIcon />
        {permissionKVS && <FeedbackIcon onClick={() => setShowFeedbackPanel(true)} />}
        <FeedbackPanel
          show={showFeedbackPanel}
          setShow={setShowFeedbackPanel}
          docSetId={docSetId}
          documentId={documentInfo.documentId!}
          validityDate={validityDate || new Date()}
        />
        <Box display={['none', 'block']}>{quickSearchInput}</Box>
        <ValidityDatePicker
          date={validityDate}
          onChange={changeValidityDate}
          disabled={loading || quickSearch === HIST_GEHALTSTABELLE_NAME}
        />
      </Flex>
    ) : (
      <Box />
    )
  const renderSourceInfo = () => <KvSourceInfoPanel documentId={documentInfo.documentId} paraId={paraId} />
  return (
    <>
      <ReaderHeaderPanel logo={logo} search={searchPanel} />
      <ReaderToolbarPanel
        tools={tools}
        breadcrumbs={breadcrumbs}
        height={theme.layout.reader.toolbarHeight}
        renderSourceInfo={renderSourceInfo}
      />
    </>
  )
}
