import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useHistory} from 'react-router-dom'
import {authenticator} from '../../app/auth/SessionPlusAuthenticator'
import {Button} from '../../commons/components/button/Button'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {HeaderPanel} from '../../commons/components/header/HeaderPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {DigitalLogo} from '../../digital/components/digital-logo/DigitalLogo'
import {digitalLoginPage} from '../../digital/store/digital.paths'
import {authDetailsOverview} from '../auth-details.paths'
import {AuthenticationDetailsPanel} from '../components/AuthenticationDetailsPanel'

export const AuthDetailsOverviewPage: React.FC = () => {
  const isAuthenticated = authenticator.isAuthenticated()
  const history = useHistory()

  const header = <HeaderPanel logo={<DigitalLogo />} noSearch />
  const footer = <FooterPanel />
  return (
    <ContentLayout header={header} footer={footer}>
      <DigitalHelmet title="Authentication Details" canonical={authDetailsOverview()} />
      <Flex height={500} fullWidth center>
        {isAuthenticated && <AuthenticationDetailsPanel />}
        {!isAuthenticated && (
          <Flex direction="column">
            <Box>Bitte melden Sie sich bei ÖGB-Verlag Digital an.</Box>
            <Box pt={4}>
              <Button
                text="Login"
                linkTo={() => history.push(digitalLoginPage(encodeURIComponent(window.location.href)))}
              />
            </Box>
          </Flex>
        )}
      </Flex>
    </ContentLayout>
  )
}
