import {History} from 'history'
import {Epic, ofType} from 'redux-observable'
import {merge, of} from 'rxjs'
import {catchError, debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators'
import {authenticator, Permission} from '../../app/auth/SessionPlusAuthenticator'
import {RootState, Services} from '../../app/types'
import {loadTableOfContentService$, loadWebcDocumentService$} from '../../commons/store/webc.service'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {cleanUrlPathPart} from '../../commons/utils/urlUtils'
import {selectDigitalToCs} from '../../digital/store/digital.selectors'
import {throwError} from '../../error/store/error.actions'
import {
  KvAction,
  kvDocumentNotAvailable,
  kvEmptySearchResult,
  KvFilters,
  KvLoadChanges,
  KvLoadChangesMore,
  kvLoadChangesSuccess,
  KvLoadContent,
  kvLoadContentSuccess,
  kvLoadDocsetInfoSuccess,
  KvLoadDocument,
  kvLoadDocumentError,
  kvLoadDocumentSuccess,
  KvLoadNews,
  KvLoadNewsMore,
  kvLoadNewsSuccess,
  KvLoadSourceInfo,
  kvLoadSourceInfoSuccess,
  kvRedirectContent,
  KvSearch,
  KvSearchAutoComplete,
  KvSearchMore,
  kvSearchMoreSuccess,
  kvSearchSuccess,
  KvSendFeedback,
  kvSendFeedbackError,
  kvSendFeedbackSuccess,
  searchAutoCompleteSuccess,
} from './kv.actions'
import {kvPathContentPage, kvPathDocument, kvPathOverview} from './kv.paths'
import {
  selectKvChangesCurrentPage,
  selectKvChangesItems,
  selectKvNews,
  selectKvNewsCurrentPage,
  selectKvReaderContent,
  selectKvReaderParams,
  selectKvReaderStructure,
  selectKvSearchFilters,
  selectKvSearchPage,
  selectKvSearchResult,
  selectKvSearchUserQuery,
} from './kv.selectors'
import {
  loadChanges$,
  loadDocsetInfo$,
  loadDocsetStructureService$,
  loadNewsService$,
  loadSourceInfoService$,
  loadTimeSliceDocumentService$,
  searchKvAutoSuggestionService$,
  searchKvService$,
  sendFeedbackService$,
} from './kv.services'
import {DocSetInfo, DocsetStructure, KvActionKeys, KvReaderQueryParams} from './kv.types'
import {
  createDocsetName,
  createDocsetNamePathPart,
  findKvDocumentInfo,
  haveKvParamsChanged,
  isInSearchMode,
  isMainDocument,
} from './kv.utils'

interface KvEpic extends Epic<KvAction, KvAction, RootState, Services> {}

const updateKvReaderUrl = (
  action: KvLoadDocument,
  docsetStructure: DocsetStructure,
  documentInfo: DocumentInfo,
  kvParams: KvReaderQueryParams,
  invalidState: boolean,
  history: History,
  isRedirect: boolean
) => {
  const link = kvPathDocument(
    action.docSetId,
    documentInfo.documentId || '',
    createDocsetNamePathPart(docsetStructure.main.link.name, docsetStructure.main.angArb),
    documentInfo.documentName || '',
    {
      ...kvParams,
      paraId: documentInfo.paraId,
      isDocsetOnlyRequest: isRedirect ? 'true' : undefined,
    }
  )
  history.replace(link)
}

// CP-513: Because of this replacement, the 'back to docset' leads to the first KV document
// const updateFallbackReaderUrl = (action: KvLoadDocument, kvParams: KvReaderQueryParams, history: History) => {
//   delete kvParams.paraId
//   const link = kvPathKvDocSetId(action.docSetId, kvParams)
//   history.replace(link)
// }

export const kvLoadContent$: KvEpic = (action$, state$, {ajax, history}) => {
  return action$.pipe(
    ofType<KvLoadDocument>(KvActionKeys.LOAD_DOCUMENT),
    switchMap((action) => {
      const store = state$.value
      const storedStructure = selectKvReaderStructure(store)
      const storedKvParams = selectKvReaderParams(store)
      const actionKvParams = action.kvParams || {}
      const invalidState = haveKvParamsChanged(actionKvParams, storedKvParams)
      const searchMode = isInSearchMode(actionKvParams)

      return loadDocsetStructureService$(ajax, action.docSetId, actionKvParams, invalidState, storedStructure).pipe(
        switchMap((docsetStructure) => {
          if (!action.documentId && !authenticator.hasPermission(Permission.KVSYSTEM)) {
            const docSetName = createDocsetName(docsetStructure, true)
            return loadDocsetInfo$(ajax, action.docSetId).pipe(
              map((docSetInfo) => kvLoadDocsetInfoSuccess(docSetInfo, docsetStructure, docSetName)),
              catchError(() => {
                const minimalDocSetInfo: DocSetInfo = {
                  id: docsetStructure.main.link.id,
                  name: docsetStructure.main.link.name,
                }
                return of(kvLoadDocsetInfoSuccess(minimalDocSetInfo, docsetStructure, docSetName))
              })
            )
          }

          const documentInfo = findKvDocumentInfo(docsetStructure, searchMode, action.documentId, actionKvParams.paraId)
          if (!documentInfo) {
            // CP-513 (see above)
            // updateFallbackReaderUrl(action, actionKvParams, history)
            const displayName = createDocsetName(docsetStructure, true)

            // the structure does not contain the requested document (e.g. the requested node has set 'noContent=true')
            if (actionKvParams.quickSearch === undefined && actionKvParams.userQuery === undefined) {
              return of(kvDocumentNotAvailable(actionKvParams, docsetStructure, displayName))
            }

            // there are no documents in the search result
            return of(kvEmptySearchResult(actionKvParams, docsetStructure, displayName))
          }

          updateKvReaderUrl(
            action,
            docsetStructure,
            documentInfo,
            actionKvParams,
            invalidState,
            history,
            action.documentId === undefined || action.kvParams?.isDocsetOnlyRequest === 'true'
          )

          // Stop here since no document was requested
          if (!action.documentId) {
            return of(kvRedirectContent())
          }

          const currentDocument = selectKvReaderContent(state$.value)
          const isMain = isMainDocument(docsetStructure, documentInfo.documentId)
          const docsetDisplayName = createDocsetName(docsetStructure, isMain)
          return loadTimeSliceDocumentService$(
            ajax,
            documentInfo,
            currentDocument,
            actionKvParams,
            storedKvParams
          ).pipe(
            map((content) =>
              kvLoadDocumentSuccess(docsetStructure, content, documentInfo, docsetDisplayName, actionKvParams)
            )
          )
        }),
        catchError((e) => {
          if (e.status === 404) {
            return of(kvLoadDocumentError())
          }
          return merge(of(kvLoadDocumentError(), throwError(e)))
        })
      )
    })
  )
}

const isKvFiltersSet = (kvFilters?: KvFilters) => {
  if (kvFilters === undefined) {
    return false
  }
  return Object.keys(kvFilters).find((key) => kvFilters[key] !== undefined) !== undefined
}

const kvSearch$: KvEpic = (action$, state$, {ajax, history}) =>
  action$.pipe(
    ofType<KvSearch>(KvActionKeys.SEARCH),
    switchMap((action) => {
      const {userQuery, filter} = action
      // append userQuery param to URL search location
      history.replace(kvPathOverview(userQuery, filter))

      // stop here if the query is empty -> no server round-trip necessary
      if (userQuery === undefined && !isKvFiltersSet(filter)) {
        return of(kvSearchSuccess(null))
      }

      const previousSearchId = selectKvSearchResult(state$.value)?.tracking?.searchId
      return searchKvService$(ajax, userQuery, filter, undefined, previousSearchId).pipe(
        map((searchResult) => kvSearchSuccess(searchResult)),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const kvSearchMore$: KvEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<KvSearchMore>(KvActionKeys.SEARCH_MORE),
    switchMap((action) => {
      const userQuery = selectKvSearchUserQuery(state$.value) || ''
      const filters = selectKvSearchFilters(state$.value)
      const nextPage = selectKvSearchPage(state$.value) + 1
      const previousSearchId = selectKvSearchResult(state$.value)?.tracking?.searchId
      return searchKvService$(ajax, userQuery, filters ?? undefined, nextPage, previousSearchId).pipe(
        map((searchResult) => kvSearchMoreSuccess(searchResult, nextPage)),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const kvSearchAutoComplete$: KvEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<KvSearchAutoComplete>(KvActionKeys.SEARCH_AUTO_COMPLETE),
    distinctUntilChanged(),
    debounceTime(250),
    switchMap((action) => {
      return searchKvAutoSuggestionService$(ajax, action.userQuery).pipe(
        map((items) => searchAutoCompleteSuccess(items)),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const kvSourceInfo$: KvEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<KvLoadSourceInfo>(KvActionKeys.LOAD_SOURCE_INFO),
    switchMap((action) => {
      if (!action.documentId || !action.paraId) {
        return of(kvLoadSourceInfoSuccess(null))
      }
      return loadSourceInfoService$(ajax, action.documentId, action.paraId).pipe(
        map((sourceInfo) => kvLoadSourceInfoSuccess(sourceInfo)),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const kvLoadChanges$: KvEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<KvLoadChanges>(KvActionKeys.LOAD_CHANGES),
    switchMap((action) => {
      const currentItems = selectKvChangesItems(state$.value)
      // check if change items have already been loaded
      if (currentItems.length > 0) {
        return of(kvLoadChangesSuccess(currentItems.slice(0, 20), 0))
      }
      return loadChanges$(ajax, 0).pipe(
        map((changes) => kvLoadChangesSuccess(changes.items, 0)),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const kvLoadChangesMore$: KvEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<KvLoadChangesMore>(KvActionKeys.LOAD_CHANGES_MORE),
    switchMap((action) => {
      const nextPage = selectKvChangesCurrentPage(state$.value) + 1
      return loadChanges$(ajax, nextPage).pipe(
        map((changes) => {
          const nextItems = [...selectKvChangesItems(state$.value), ...changes.items]
          return kvLoadChangesSuccess(nextItems, nextPage)
        }),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const kvLoadNews$: KvEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<KvLoadNews>(KvActionKeys.LOAD_NEWS),
    switchMap((action) => {
      return loadNewsService$(ajax, 0).pipe(
        map((result) => kvLoadNewsSuccess(result, 0)),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const kvLoadNewsMore$: KvEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<KvLoadNewsMore>(KvActionKeys.LOAD_NEWS_MORE),
    switchMap((action) => {
      const nextPage = selectKvNewsCurrentPage(state$.value) + 1
      const currentDocuments = selectKvNews(state$.value)
      return loadNewsService$(ajax, nextPage).pipe(
        map((result) => {
          const newDocuments = result.documents
          const mergedResult = {...result}
          if (newDocuments) {
            mergedResult.documents = [...currentDocuments, ...newDocuments]
          }
          return kvLoadNewsSuccess(mergedResult, nextPage)
        }),
        catchError((e) => of(throwError(e)))
      )
    })
  )

const loadWebCDocument$: KvEpic = (action$, state$, {ajax, history}) => {
  return action$.pipe(
    ofType<KvLoadContent>(KvActionKeys.LOAD_CONTENT),
    switchMap((action) => {
      const currentTocs = selectDigitalToCs(state$.value)
      return loadTableOfContentService$(ajax, action.tocId, currentTocs[action.tocId]).pipe(
        switchMap((toc) => {
          const documentId = action.documentId || toc.rootDocumentId
          return loadWebcDocumentService$(ajax, documentId).pipe(
            map((contentNode) => {
              const title = contentNode.title
              if (action.documentId) {
                history.replace(kvPathContentPage(action.documentId, cleanUrlPathPart(title)))
              }
              return kvLoadContentSuccess(toc, contentNode)
            })
          )
        }),
        catchError((e) => of(throwError(e)))
      )
    })
  )
}

const kvSendFeedback$: KvEpic = (action$, state$, {ajax}) => {
  return action$.pipe(
    ofType<KvSendFeedback>(KvActionKeys.SEND_FEEDBACK),
    switchMap((action) => {
      return sendFeedbackService$(ajax, action.text, action.url, action.docSetId, action.documentId, action.date).pipe(
        map(() => kvSendFeedbackSuccess())
      )
    }),
    catchError(() => of(kvSendFeedbackError()))
  )
}

export const kvEpics = [
  kvLoadContent$,
  kvSearch$,
  kvSearchMore$,
  kvSearchAutoComplete$,
  kvSourceInfo$,
  kvLoadChanges$,
  kvLoadChangesMore$,
  kvLoadNews$,
  kvLoadNewsMore$,
  loadWebCDocument$,
  kvSendFeedback$,
]
