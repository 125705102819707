import {Box, Text} from '@indoqa/style-system'
import {getInitialState} from '@oegbv/ui-shared'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {authenticator, Permission} from '../../../app/auth/SessionPlusAuthenticator'
import {Theme} from '../../../app/theme'
import {
  ActivationState,
  getArfbrDebugFromSessionStorage,
  getCurrentSetFromSessionStorage,
  getKuSupportFromSessionStorage,
  saveArfbrDebugToSessionStorage,
  saveCurrentSetToSessionStorage,
  saveKuSupportToSessionStorage,
} from './settings'

interface Props {
  show: boolean
  renderSourceInfo?: () => JSX.Element
}

const renderPermission = (permission: Permission) => (
  <Text>
    {permission}:{' '}
    {authenticator.hasPermission(permission) ? (
      <span style={{color: 'green'}}>true</span>
    ) : (
      <span style={{color: 'red'}}>false</span>
    )}
  </Text>
)

const renderUserInfo = () => {
  if (!authenticator.isAuthenticated()) {
    return 'ANONYMOUS'
  }
  return `${authenticator.currentUser()?.name} | ${authenticator.currentUser()?.email}`
}

export const SettingDetailsPanel: React.FC<Props> = ({show, renderSourceInfo}) => {
  const [set, setSet] = React.useState(getCurrentSetFromSessionStorage())
  const [kuSupport, setKuSupport] = React.useState(getKuSupportFromSessionStorage())
  const [arfbrDebug, setArfbrDebug] = React.useState(getArfbrDebugFromSessionStorage())
  const {theme} = useFela<Theme>()

  const initialState = getInitialState()

  const containerStyle: IStyle = {
    top: 0,
    left: 0,
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#FFF',
    color: theme.colors.text,
    display: show ? 'block' : 'none',
    zIndex: theme.zIndexes.modal,
    ...theme.fontStyles.base,
  }

  const innerContainerStyle: IStyle = {
    padding: theme.spacing.space3,
    overflowY: 'auto',
    height: '100%',
    width: '100%',
  }

  const changeSet = (nextSet: string) => {
    if (set === nextSet) {
      return
    }
    setSet(nextSet)
    // store the set to the browser session
    saveCurrentSetToSessionStorage(nextSet)
    // reload the page
    window.location.reload(true)
  }

  const changeKuSupport = (next: ActivationState) => {
    if (kuSupport === next) {
      return
    }
    setKuSupport(next)
    saveKuSupportToSessionStorage(next)
    window.location.reload(true)
  }

  const changeArfbrDebugSupport = (next: ActivationState) => {
    if (arfbrDebug === next) {
      return
    }
    setArfbrDebug(next)
    saveArfbrDebugToSessionStorage(next)
    window.location.reload(true)
  }

  return (
    <Box style={containerStyle}>
      <Box style={innerContainerStyle}>
        <h1>Content-Pools UI Einstellungen</h1>

        <h3>Set</h3>
        <p>
          <input type="radio" value="live" checked={set === 'LIVE'} onChange={() => changeSet('LIVE')} />
          &nbsp;Live&nbsp;
          <input type="radio" value="preview" checked={set === 'PREVIEW'} onChange={() => changeSet('PREVIEW')} />
          &nbsp;Preview&nbsp;
        </p>
        <h3>KV-Kurzübersicht</h3>
        <p>
          <input
            type="radio"
            value="yes"
            checked={kuSupport === ActivationState.YES}
            onChange={() => changeKuSupport(ActivationState.YES)}
          />
          &nbsp;Ja&nbsp;
          <input
            type="radio"
            value="no"
            checked={kuSupport === ActivationState.NO}
            onChange={() => changeKuSupport(ActivationState.NO)}
          />
          &nbsp;Nein&nbsp;
        </p>
        <h3>ARfBR Debug Info</h3>
        <p>
          <input
            type="radio"
            name="arfbr_debug"
            value="yes"
            checked={arfbrDebug === ActivationState.YES}
            onChange={() => changeArfbrDebugSupport(ActivationState.YES)}
          />
          &nbsp;Ja&nbsp;
          <input
            type="radio"
            value="no"
            name="arfbr_debug"
            checked={arfbrDebug === ActivationState.NO}
            onChange={() => changeArfbrDebugSupport(ActivationState.NO)}
          />
          &nbsp;Nein&nbsp;
        </p>

        <h3>User</h3>
        <p>{renderUserInfo()}</p>

        <h3>Permissions</h3>
        <p>{renderPermission(Permission.KVSYSTEM)}</p>
        <p>{renderPermission(Permission.BOOKS)}</p>
        <p>{renderPermission(Permission.ARFBR_FREE)}</p>
        <p>{renderPermission(Permission.ARFBR_PLUS)}</p>
        <p>{renderPermission(Permission.ARFBR_PREMIUM)}</p>

        <h3>Commit ID</h3>
        <p>
          {initialState.commitShortId} <small>({initialState.commitTime})</small>
        </p>

        {renderSourceInfo && show && renderSourceInfo()}

        <h3>Authentication</h3>
        <p>
          <strong>Realm</strong>: <br /> {initialState.conf.auth.realm}
        </p>
        <p>
          <strong>Client Id</strong>: <br /> {initialState.conf.auth.clientId}
        </p>
        <p>
          <strong>Endpoint</strong>: <br /> {initialState.conf.auth.url}
        </p>
      </Box>
    </Box>
  )
}
