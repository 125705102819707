import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useFela} from 'react-fela'
import {useHistory} from 'react-router-dom'
import {Theme} from '../../../app/theme'
import {SearchInput} from '../../../commons-shared/search-input/SearchInput'
import {StyleConfig} from '../../../commons-shared/search-input/SearchInput.types'
import {bookPathOverview, bookQueryParams} from '../../store/book.paths'

export const EbookSearchInput = () => {
  const {theme} = useFela<Theme>()
  const history = useHistory()

  const query = useQueryParam(bookQueryParams.userQuery)

  const styleConfig: Partial<StyleConfig> = {
    borderRadius: 0,
    borderStyle: `2px solid ${theme.colors.contextMenu}`,
  }

  return (
    <SearchInput
      inputValue={query || ''}
      items={[]}
      search={(input) => {
        history.push(bookPathOverview(input))
      }}
      clear={() => {
        history.push(bookPathOverview())
      }}
      autofocus
      reload={() => undefined}
      placeholder="Suche nach Titel, Autor:innen, Rechtsstand oder Reihe"
      styleConfig={styleConfig}
    />
  )
}
