import '@indoqa/react-app-polyfill/ie11'
import {createFelaConfig} from '@indoqa/style-system'
import 'core-js/es/string/ends-with'
import 'core-js/es/symbol/iterator'
import {createRenderer} from 'fela'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './app/App'
import {authenticator} from './app/auth/SessionPlusAuthenticator'
import BaseApp from './app/BaseApp'
import history from './app/history'
import initStore from './app/initStore'
import theme from './app/theme'

const rootEl = document.getElementById('app')

authenticator.init(() => {
  const store = initStore()
  const felaConfig = createFelaConfig(theme.breakpoints, undefined, '_')
  const renderer = createRenderer(felaConfig)

  ReactDOM.render(
    <BaseApp history={history} store={store} renderer={renderer}>
      <App />
    </BaseApp>,
    rootEl
  )
})
