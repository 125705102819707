import {Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {useHistory} from 'react-router-dom'
import {authenticator} from '../../../app/auth/SessionPlusAuthenticator'
import {Theme} from '../../../app/theme'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {digitalLoginPage} from '../../../digital/store/digital.paths'
import {ReactComponent as KeyIcon} from '../../icons/key.svg'

export const LoginButton: React.FC = () => {
  const {css, theme} = useFela<Theme>()
  const loggedIn = authenticator.isAuthenticated()
  const history = useHistory()

  const buttonStyle: IStyle = {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  }
  const textStyle: IStyle = {
    display: 'none',
    tablet: {
      display: 'block',
      position: 'relative',
      color: theme.colors.text,
      marginRight: theme.spacing.space1,
      fontSize: theme.fontSizes.small,
    },
  }

  const authenticate = () => {
    if (authenticator.isAuthenticated()) {
      authenticator.logout()
    } else {
      history.push(digitalLoginPage(encodeURIComponent(window.location.href)))
    }
  }

  return (
    <button className={css(buttonStyle)} onClick={() => authenticate()}>
      <Text<Theme> style={textStyle} fontStyle="brandBold">
        {loggedIn ? 'Logout' : 'Login'}
      </Text>
      <Text style={{position: 'relative', top: -2}}>
        <ThemedIcon size={20}>
          <KeyIcon />
        </ThemedIcon>
      </Text>
    </button>
  )
}
