import querystring from 'query-string'
import {Observable, of} from 'rxjs'
import {AjaxCreationMethod, AjaxResponse} from 'rxjs/internal/observable/dom/AjaxObservable'
import {catchError, map} from 'rxjs/operators'
import {authenticator} from '../../app/auth/SessionPlusAuthenticator'
import {AutoCompleteItemTargetType} from '../../commons-shared/search-input/SearchInput.types'
import {ActivationState, getKuSupportFromSessionStorage} from '../../commons/components/settings/settings'
import {addClientInfo} from '../../commons/idqsa/idqsa'
import {getHeaders} from '../../commons/store/service-utils'
import {ContentNode} from '../../commons/types/ContentNode'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {SearchResult} from '../../commons/types/SearchResult'
import {SourceInfo} from '../../commons/types/SourceInfo'
import {formatValidityDateApi} from '../utils/validityDateUtils'
import {KvFilters} from './kv.actions'
import {GEHALTSTABELLE_NAME, HIST_GEHALTSTABELLE_NAME} from './kv.constants'
import {
  DocSetInfo,
  DocsetStructure,
  KvAutoCompleteItem,
  KvChanges,
  KvDocumentLookupInfo,
  KvReaderQueryParams,
} from './kv.types'
import {getKvDocumentName, getValidityDate} from './kv.utils'

const KV_BASE_PATH = '/api/v1/kv'

export const loadDocsetStructureService$ = (
  ajax: AjaxCreationMethod,
  docSetId: string,
  kvParams: KvReaderQueryParams,
  enforceReload: boolean,
  currentStructure: DocsetStructure | null
): Observable<DocsetStructure> => {
  if (!enforceReload && currentStructure && docSetId === currentStructure.main.link.id) {
    return of(currentStructure)
  }

  const queryParams: {[key: string]: string} = {}
  if (kvParams.quickSearch) {
    // special case: 'Historische Gehaltstabelle -> rewrite to 'Gehaltstabelle' and set the validityDate to undefined
    if (kvParams.quickSearch === HIST_GEHALTSTABELLE_NAME) {
      queryParams['quick-search'] = GEHALTSTABELLE_NAME
    } else {
      queryParams['quick-search'] = kvParams.quickSearch
    }
  }

  // handle the special cases of validity date if it is missing or if historical data are requested
  const formattedDate = getValidityDate(kvParams)
  if (formattedDate) {
    queryParams['valid-on'] = formattedDate
  }
  if (kvParams.userQuery) {
    queryParams['query'] = kvParams.userQuery
  }

  // prune all nodes from the structure which aren't search results
  if (kvParams.quickSearch || kvParams.userQuery) {
    queryParams['prune'] = 'true'
  }

  // enable/disable Kurzübersichten
  const kuSupport = getKuSupportFromSessionStorage()
  if (kuSupport === ActivationState.NO) {
    queryParams['remove-overview'] = 'true'
  }

  const urlQueryString = querystring.stringify(queryParams)
  return ajax.getJSON(
    `${KV_BASE_PATH}/docsets/${docSetId}/structure${urlQueryString.length > 0 ? `?${urlQueryString}` : ''}`,
    getHeaders()
  )
}

export const loadTimeSliceDocumentService$ = (
  ajax: AjaxCreationMethod,
  documentInfo: DocumentInfo,
  contentNode: ContentNode | null,
  kvParams: KvReaderQueryParams,
  storedKvParams: KvReaderQueryParams
): Observable<ContentNode> => {
  // avoid loading content which is already available in the Redux store
  if (
    contentNode &&
    contentNode.id === documentInfo.documentId &&
    kvParams.userQuery === storedKvParams.userQuery &&
    kvParams.quickSearch === storedKvParams.quickSearch
  ) {
    return of(contentNode)
  }

  // prepare the query parameters
  const queryParams: {[key: string]: string} = {}
  if (kvParams.userQuery) {
    queryParams['query'] = kvParams.userQuery
  }
  if (kvParams.quickSearch) {
    // special case: 'Historische Gehaltstabelle -> rewrite to 'Gehaltstabelle' and set the validityDate to undefined
    if (kvParams.quickSearch === HIST_GEHALTSTABELLE_NAME) {
      queryParams['quick-search'] = GEHALTSTABELLE_NAME
    } else {
      queryParams['quick-search'] = kvParams.quickSearch
    }
  }

  // perform the server call
  const urlQueryString = querystring.stringify(queryParams)
  return ajax.getJSON(
    `${KV_BASE_PATH}/time-slices/${documentInfo.documentId}${urlQueryString.length > 0 ? `?${urlQueryString}` : ''}`,
    getHeaders()
  )
}

export const searchKvService$ = (
  ajax: AjaxCreationMethod,
  userQuery?: string,
  filters?: KvFilters,
  page?: number,
  previousSearchId?: string
): Observable<SearchResult> => {
  const queryParams: {[key: string]: string} = {}
  queryParams['query'] = userQuery || ''
  queryParams['page-size'] = '25'
  queryParams['valid-on'] = formatValidityDateApi(new Date())!
  if (filters) {
    if (filters.ang_arb) {
      queryParams['ang_arb'] = filters.ang_arb
    }
    // cannot take the from/to values from the request if the filters come from query parameters
    if (filters.contentModified) {
      if (filters.contentModified === '1MONTHS') {
        queryParams['contentModified-from'] = 'NOW/DAYS-1MONTHS'
        queryParams['contentModified-to'] = 'NOW/DAYS'
      }
      if (filters.contentModified === '12MONTHS') {
        queryParams['contentModified-from'] = 'NOW/DAYS-12MONTHS'
        queryParams['contentModified-to'] = 'NOW/DAYS-1MONTHS'
      }
      if (filters.contentModified === 'end') {
        queryParams['contentModified-to'] = 'NOW/DAYS-12MONTHS'
      }
    }
    if (filters.union) {
      queryParams['union'] = filters.union
    }
    if (filters.province) {
      queryParams['province'] = filters.province
    }
    if (filters.validOn) {
      queryParams['valid-on'] = filters.validOn
    }
    if (filters.sector) {
      queryParams['sector'] = filters.sector
    }
  }
  if (page && page > 0) {
    queryParams['page'] = String(page)
  }

  addClientInfo(queryParams, previousSearchId)

  return ajax.getJSON(`${KV_BASE_PATH}/search?${querystring.stringify(queryParams)}`, getHeaders())
}

export const searchKvAutoSuggestionService$ = (
  ajax: AjaxCreationMethod,
  userQuery: string
): Observable<KvAutoCompleteItem[]> => {
  if (userQuery.length <= 2) {
    return of([])
  }
  const queryParams: {[key: string]: string} = {}
  queryParams['query'] = userQuery
  queryParams['page-size'] = '100'
  addClientInfo(queryParams)

  return ajax.getJSON(`${KV_BASE_PATH}/auto-suggest?${querystring.stringify(queryParams)}`, getHeaders()).pipe(
    map((response: SearchResult) => {
      if (!response || !response.documents) {
        return []
      }
      return response.documents.map((doc) => {
        return {
          proposal: '',
          targetType: AutoCompleteItemTargetType.SUGGESTION,
          text: doc.name,
          docSetId: doc.id,
          highlightedName: getKvDocumentName(doc),
        }
      })
    }),
    catchError(() => of([]))
  )
}

export const loadSourceInfoService$ = (
  ajax: AjaxCreationMethod,
  documentId: string,
  paraId: string
): Observable<SourceInfo> => {
  return ajax.getJSON(`${KV_BASE_PATH}/source-info/${documentId}?element-id=${paraId}`, getHeaders())
}

export const loadChanges$ = (ajax: AjaxCreationMethod, page: number): Observable<KvChanges> => {
  return ajax.getJSON(`${KV_BASE_PATH}/changes?page-size=20&language=de&page=${page}`, getHeaders())
}

export const loadNewsService$ = (ajax: AjaxCreationMethod, page: number): Observable<SearchResult> => {
  return ajax.getJSON(`${KV_BASE_PATH}/news?page-size=20&page=${page}`, getHeaders())
}

export const loadDocsetInfo$ = (ajax: AjaxCreationMethod, docSetId: string): Observable<DocSetInfo> => {
  return ajax.getJSON(`${KV_BASE_PATH}/docsets/${docSetId}/info`, getHeaders())
}

export const sendFeedbackService$ = (
  ajax: AjaxCreationMethod,
  text: string,
  url: string,
  docSetId: string,
  documentId: string,
  date: Date
): Observable<AjaxResponse> => {
  const user = authenticator.currentUser()
  const message = {
    url,
    docSetId,
    documentId,
    text,
    userId: user?.email ?? 'UNKNOWN',
    userName: user?.name ?? 'UNKNOWN',
    date,
  }
  const headers = {
    ...getHeaders(),
    'Content-Type': 'application/json',
  }
  return ajax.post(`${KV_BASE_PATH}/feedback`, message, headers)
}

const e = encodeURIComponent

export const loadKvRedirectInfo$ = (
  ajax: AjaxCreationMethod,
  id: string,
  from: string
): Observable<KvDocumentLookupInfo> => {
  return ajax.getJSON(`/api/v1/kv/lookup?id=${e(id)}&from=${e(from)}`, getHeaders())
}
