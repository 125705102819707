import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {authenticator} from '../../../app/auth/SessionPlusAuthenticator'
import {searchResultWidthRules} from '../../../app/mixins'
import {Theme} from '../../../app/theme'
import {StringFacetPanel} from '../../../commons/components/facets/StringFacetPanel'
import {SearchResult} from '../../../commons/types/SearchResult'
import {PerformFiltering} from '../../pages/ArSearchPage'
import {ArFilters} from '../../store/ar.actions'
import {ArTestzugangButton} from '../cta/ArTestzugangButton'
import {ArTypeFilter} from '../filters/ArTypeFilter'
import {ArSearchMore} from '../search-more/ArSearchMore'
import {ArResultItemFactory} from './ArResultItemFactory'

interface Props {
  searchResult: SearchResult | null
  userInput: string
  filters: ArFilters
  page?: number
  performFiltering: PerformFiltering
}

const MAX_DOCUMENTS = 100

export const ArSearchResultPanel = ({searchResult, userInput, filters, page, performFiltering}: Props) => {
  const {theme} = useFela<Theme>()
  const isAuthenticated = authenticator.isAuthenticated()

  if (!searchResult) {
    return null
  }

  const {facets} = searchResult

  const containsVideos = !!facets?.videos?.find((facetValue) => {
    return facetValue.name === 'videos' && facetValue.count > 0
  })

  // don't allow overflow and max 100 documents
  const hasMore =
    searchResult.documents &&
    searchResult.documentsFound > searchResult.documents.length &&
    searchResult.documents.length < MAX_DOCUMENTS
  const noResults = searchResult.documentsFound <= 0
  const renderResultItems = () => {
    return searchResult.documents?.map((resultDocument) => {
      return (
        <ArResultItemFactory
          key={resultDocument.id}
          userInput={userInput}
          filters={filters}
          page={page}
          resultDocument={resultDocument}
          searchId={searchResult.tracking?.searchId}
        />
      )
    })
  }

  return (
    <Flex fullWidth nowrap>
      <Box grow={1} style={searchResultWidthRules(theme)} pl={1} pb={4}>
        {noResults && 'Leider hat Ihre Suche zu keinen Ergebnissen geführt.'}
        {renderResultItems()}
        {hasMore && <ArSearchMore userInput={userInput} filters={filters} page={page} />}
      </Box>

      <Box grow={0} width={375} pl={4} ml={4} display={['none', 'block']}>
        <Box<Theme> fontStyle="brandBold" fontSize="big" pb={2} pt={1}>
          Suche verfeinern
        </Box>

        <Box pb={2}>
          <ArTypeFilter
            onBookTitleOnly={(nextValue) => {
              if (nextValue) {
                performFiltering({bookTitleOnly: true})
              } else {
                performFiltering({})
              }
            }}
            onVideoOnly={(nextValue) => {
              if (nextValue) {
                performFiltering({videoOnly: true})
              } else {
                performFiltering({})
              }
            }}
            bookTitleOnly={filters.bookTitleOnly || false}
            videoOnly={filters.videoOnly || false}
            disableVideoFacet={!containsVideos}
          />
        </Box>

        {facets && (
          <>
            {facets.handbook_name && (
              <StringFacetPanel<ArFilters>
                name="Bücher"
                currentFilter="handbook_name"
                values={facets.handbook_name}
                filters={filters}
                performFiltering={(nextFilters) => {
                  nextFilters.skriptum_name = undefined
                  performFiltering(nextFilters)
                }}
              />
            )}
            {facets.skriptum_name && (
              <StringFacetPanel<ArFilters>
                name="VÖGB-Skripten"
                currentFilter="skriptum_name"
                values={facets.skriptum_name}
                filters={filters}
                performFiltering={(nextFilters) => {
                  nextFilters.handbook_name = undefined
                  performFiltering(nextFilters)
                }}
              />
            )}
          </>
        )}
        {!isAuthenticated && (
          <Box p={4} mt={4}>
            <ArTestzugangButton />
          </Box>
        )}
      </Box>
    </Flex>
  )
}
